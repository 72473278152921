import type { ANCHOR_IDS } from '~/constants/anchors'

export function scrollToElement(id: typeof ANCHOR_IDS[keyof typeof ANCHOR_IDS]) {
  const rect = document.getElementById(id)!.getBoundingClientRect()
  window.scrollTo({
    top: rect.top,
    behavior: 'smooth',
  })
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
